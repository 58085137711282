import React, { memo, useContext, useState } from 'react';
import { Dropdown, Flex, MenuProps } from 'antd';

import Arrow from 'shared/ui/Icons/Arrow';
import { SizeProps } from 'shared/interface/product.interfce';
import { Text } from 'shared/ui/Paragraph/ui/Text';
import { StyleContext } from 'app/providers/lib/StyleContext';
import { borderRadius } from 'app/config/constants';

import { sizes } from '../lib/options';

export const SizeSelect = memo((props: SizeProps) => {
    const { size, setSize } = props;

    const { componentBg, colorText } = useContext(StyleContext);

    const [isOpen, setIsOpen] = useState(false);
    const onClick: MenuProps['onClick'] = item => {
        setSize(item.key);
    };

    const items: MenuProps['items'] = sizes;

    return (
        <Dropdown
            disabled
            trigger={['click']}
            menu={{ items, onClick }}
            overlayStyle={{
                width: 'max-content',
                background: componentBg.hex,
            }}
            onOpenChange={open => setIsOpen(open)}
        >
            <Flex
                style={{
                    background: componentBg.hex,
                    padding: '4px 12px',
                    borderRadius: borderRadius.small,
                    cursor: 'pointer',
                }}
                justify={'space-between'}
                align={'center'}
            >
                <div onClick={e => e.preventDefault()}>
                    <Text weight={400} size={17}>
                        Size
                    </Text>
                </div>

                <Flex vertical={false} align={'center'}>
                    <Text type={'secondary'} weight={400} size={17}>
                        {size}
                    </Text>
                    <Arrow position={isOpen ? 'top' : 'bottom'} />
                </Flex>
            </Flex>
        </Dropdown>
    );
});
